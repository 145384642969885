// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var footerContainer = "C_b6";
export var footerMenu = "C_b7";
export var menuItem = "C_br";
export var orderHorizontal = "C_b8";
export var orderVertical = "C_b9";
export var itemSeparator = "C_cb";
export var blankSeparator = "C_cc";